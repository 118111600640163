 
<template>
  <div>
    <!-- button trigger -->
    <div class="demo-inline-spacing">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-primary
        variant="outline-primary"
      >
        Ajustes Realizados
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-modal.modal-seconday
        variant="outline-secondary"
      >
        Dados Salvos
      </b-button>
      <b-button
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        v-b-modal.modal-success
        variant="outline-success"
      >
        Dados Editados
      </b-button>
      <b-button
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        v-b-modal.modal-danger
        variant="outline-danger"
      >
        Consulta Inicial Registrada
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        v-b-modal.modal-warning
        variant="outline-warning"
      >
        Aplicação de Testes Cognitivos Registrada
      </b-button>
      <b-button
        v-ripple.400="'rgba(0, 207, 232, 0.15)'"
        v-b-modal.modal-info
        variant="outline-info"
      >
        Pronto! Cadastro 
        Realizado com Sucesso!
      </b-button>
      <b-button
        v-ripple.400="'rgba(30, 30, 30, 0.15)'"
        v-b-modal.modal-dark
        variant="outline-dark"
      >
        Paciente Adcionado
      </b-button>
    </div>

    <!-- modal -->
    <b-modal
      id="modal-primary"
      hide-footer
      hide-header
      ok-variant="dark"
      modal-class="modal-dark"
      centered
    >
    <div class="popup-img">
    <img src="assets/images/modal_icons/icon_pop-up_dados_salvos.png">
    </div>
    <h5 class="popup-title mt-2">Ajustes Realizados</h5>
    <div class="text-center mt-2">
    <b-button 
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    >
      Começar Partida!
    </b-button>
    </div>
    </b-modal>

    <b-modal
      id="modal-seconday"
      hide-footer
      hide-header
      ok-variant="dark"
      modal-class="modal-dark"
      centered
    >
    <div class="popup-img">
    <img src="assets/images/modal_icons/icon_pop-up_dados_salvos.png">
    </div>
    <h5 class="popup-title mt-2">Dados Salvos</h5>
    <div class="text-center mt-2">
    <b-button 
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    >
      Continuar
    </b-button>
    </div>
    </b-modal>

    <b-modal
      id="modal-success"
      hide-footer
      hide-header
      ok-variant="dark"
      modal-class="modal-dark"
      centered
    >
    <div class="popup-img">
    <img src="assets/images/modal_icons/icon_pop-up_paciente.png">
    </div>
    <h5 class="popup-title mt-2">Dados Editados</h5>
    <div class="text-center mt-2">
    <b-button 
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    >
      Voltar à Página Inicial
    </b-button>
    </div>
    </b-modal>

    <b-modal
      id="modal-danger"
      hide-footer
      hide-header
      ok-variant="dark"
      modal-class="modal-dark"
      centered
    >
    <div class="popup-img">
    <img src="assets/images/modal_icons/icon_pop-up_consulta.png">
    </div>
    <h5 class="popup-title mt-2">Consulta Inicial Registrada</h5>
    <div class="text-center mt-2">
    <b-button 
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    >
      Continuar
    </b-button>
    </div>
    </b-modal>

    <b-modal
      id="modal-info"
      hide-footer
      hide-header
      ok-variant="dark"
      modal-class="modal-dark"
      centered
    >
    <div class="popup-img">
    <img src="assets/images/modal_icons/icon_pop-up_check.png">
    </div>
    <h5 class="popup-title mt-2">Pronto! Cadastro Realizado com Sucesso!</h5>
    <div class="text-center mt-2">
    <b-button 
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    >
      Voltar à tela de Login
    </b-button>
    </div>
    </b-modal>

    <b-modal
      id="modal-warning"
      hide-footer
      hide-header
      ok-variant="dark"
      modal-class="modal-dark"
      centered
    >
    <div class="popup-img">
    <img src="assets/images/modal_icons/icon_pop-up_consulta.png">
    </div>
    <h5 class="popup-title mt-2">Aplicação de Testes Cognitivos Registrada</h5>
    <div class="text-center mt-2">
    <b-button 
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    >
      Continuar
    </b-button>
    </div>
    </b-modal>

    <b-modal
      id="modal-dark"
      hide-footer
      hide-header
      ok-variant="dark"
      modal-class="modal-dark"
      centered
    >

    <div class="popup-img">
    <img src="assets/images/modal_icons/icon_pop-up_paciente.png">
    </div>
    <h5 class="popup-title mt-2">Paciente Adicionado</h5>
    <div class="text-center mt-2">
    <b-button 
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    >
      Voltar à Página Inicial
    </b-button>
</div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
}
</script>
 